import React from 'react'
import background2 from '../../assets/images/blurCryptoBackgroud2.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import Introduction from '../../components/global/introduction'
import { Fragment } from 'react'
import FloatingContainer from '../../components/global/floatingContainer'
import TicketsProgressFields from './ticketprogressfields'

const TicketProgress = () => {
    return (
        <Fragment>
            <Introduction
                height={85}
                title1="Tracking Ticket Progress: Stay Informed, Stay Connected"
                mainTitle="Ticket Progress"
                title2="Transparency and Visibility: Real-time Updates, Milestone Achievements, and Effective Communication."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <FloatingContainer
                children={
                    <TicketsProgressFields />
                }
            />
        </Fragment>
    )
}

export default TicketProgress