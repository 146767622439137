import React, { Fragment } from 'react'
import './styles.scss'
import styled from 'styled-components';

const Introduction = ({ title1, mainTitle, title2, height, backgroundImage, backgroundColor, mobileBackgroundImage, mobileBackgroundColor }) => {

    const Container = styled.div`
        background-image: url(${backgroundImage});
        background-color: ${backgroundColor};

        @media (max-width: 600px) {
            background-image: url(${mobileBackgroundImage});
            background-color: ${mobileBackgroundColor};
        }
        `;

    return (
        <Fragment>
            <div className='introductionWrapper' style={{ height: `${height}vh` }}>
                <Container
                    className='introductionImageContainer'
                >
                    <div className='introductionFirstTitle' data-aos="fade-up" data-aos-duration="500">
                        <p>{title1}</p>
                    </div>
                    <div className='introductionMainTitle' data-aos="fade-up" data-aos-duration="1000">
                        <p>{mainTitle}</p>
                    </div>
                    <div className='introductionSecondTitle' data-aos="fade-up" data-aos-duration="1500">
                        <p>{title2}</p>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

export default Introduction