import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background2 from '../../assets/images/blurCryptoBackgroud2.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import FloatingContainer from '../../components/global/floatingContainer'
import SignUpFields from './signUpFields'

const SignUp = () => {
    return (
        <Fragment>
            <Introduction
                height={85}
                title1="Join Us Now!"
                mainTitle="Get Started Today!"
                title2="Register now to access our dedicated customer support platform and receive prompt assistance, personalized solutions, and a seamless support experience tailored to your needs."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <FloatingContainer
                children={
                    <SignUpFields />
                }
            />
        </Fragment>
    )
}

export default SignUp