import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './styles.scss'

const FloatingListSection = ({ data, top }) => {
    return (
        <div className='floatingWrapper' data-aos="fade-up" data-aos-duration="2000" style={{ marginTop: `${top}px` }}>
            <Container>
                <Row style={{ padding: 0 }}>
                    {
                        data?.map(data => (
                            <Col xxl={4} xl={4} lg={4} md={4} sm={4} style={{ padding: 0 }} key={data.id}>
                                <div className={`${data.className} floatingDataContainer`}>
                                    <div className='floatingDataIcon'>
                                        {data.icon}
                                    </div>
                                    <div className='floatingTitleContainer'>
                                        <p>{data.title}</p>
                                    </div>
                                    <div className='floatingDescriptionContainer'>
                                        <p>{data.description}</p>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}

export default FloatingListSection