import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background2 from '../../assets/images/blurCryptoBackgroud2.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import { floatingData, serviceHighlight } from '../../data'
import FloatingListSection from '../../components/global/floatingsection'
import ServicesCards from '../../components/services/servicesCard'
import ServiceHighlight from '../../components/services/serviceHighlight'
import StyledAccordion from '../../components/global/accordion'

const Services = () => {
    return (
        <Fragment>

            <Introduction
                height={85}
                title1="WHY WORK WITH US?"
                mainTitle="WE ARE TRUSTED"
                title2="We are One Of The Most Trustworthy Crypto Recovery Services and know how to recover your funds, and have helped hundreds of scam victims from around the world to recover millions of dollars of stolen funds."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <FloatingListSection
                top={-150}
                data={floatingData}
            />

            <ServicesCards />

            <ServiceHighlight />

            <StyledAccordion
                title='FREQUENTLY ASKED QUESTIONS'
                data={serviceHighlight}
            />
        </Fragment>
    )
}

export default Services