import React, { useEffect, useState } from 'react'
import './styles.scss'
import Cookies from 'universal-cookie';
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
import { editUser, getUserById } from '../../api';


const ProfileFields = () => {
    const cookie = new Cookies()
    const [user, setUser] = useState([])
    const [pass, setPass] = useState('');

    useEffect(() => {
        const user = getUserById(cookie.get('id'))
        user.then(response => {
            setUser(response.data)
            setPass(response.data.password)
        })
    }, [])

    const editPassword = () => {
        if (user) {
            const data = {
                password: pass,
            }

            const updateUser = editUser(user._id, data)
            updateUser.then(response => {
                if (response.success) {
                    window.location.reload()
                } else {
                    alert(response.message)
                }
            })
        }

    }


    return (
        <div className='profileFieldsWrapper'>
            <Container>
                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={6} xl={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter First Name"
                                // onBlur={formik.handleBlur}
                                // onChange={formik.handleChange}
                                value={user?.firstName}
                                name="firstname"
                                id="firstname"
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col xxl={6} xl={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Last Name"
                                // onBlur={formik.handleBlur}
                                // onChange={formik.handleChange}
                                value={user?.lastName}
                                name="lastname"
                                id="lastname"
                                disabled
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xxl={12} xl={12} lg={12}>
                    <Col xxl={6} xl={6} lg={6}>
                        <Form.Group className="mb-3" controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                value={user?.username}
                                name="username"
                                id="username"
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col xxl={6} xl={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicPhone" >
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                value={user?.phone}
                                name="phone"
                                id="phone"
                                disabled
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row xxl={12} xl={12} lg={12} className="mb-5" >
                    <Col xxl={6} xl={6} lg={6}>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={user?.email}
                                name="email"
                                id="email"
                                disabled
                            />
                        </Form.Group>
                    </Col>
                    <Col xxl={6} xl={6} lg={6}>
                        <Form.Group className="mb-3" controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(e) => setPass(e.target.value)}
                                value={pass}
                                name="password"
                                id="password"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row style={{ padding: '0px 16px' }}>
                    <Button
                        onClick={() => editPassword()}
                        className='editSubmitButton'
                    >
                        Edit
                    </Button>
                </Row>

            </Container>
        </div>
    )
}

export default ProfileFields