import React from 'react'
import background2 from '../../assets/images/blurCryptoBackgroud2.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import Introduction from '../../components/global/introduction'
import { Fragment } from 'react'
import FloatingContainer from '../../components/global/floatingContainer'
import MyTicketFields from './myticketFields'
const MyTicket = () => {
    return (
        <Fragment>
            <Introduction
                height={85}
                title1="Submit a User Ticket and Get Heard"
                mainTitle="My Ticket"
                title2="Your Voice Matters: Streamline Support, Share Feedback, and Reap Rewards."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <FloatingContainer
                children={
                    <MyTicketFields />
                }
            />
        </Fragment>
    )
}

export default MyTicket