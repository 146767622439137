import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background2 from '../../assets/images/blurCryptoBackgroud2.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import { floatingData, floatingData2 } from '../../data'
import FloatingListSection from '../../components/global/floatingsection'
import AboutUsServices from '../../components/aboutus/services'
import './styles.scss'
import Testimonials from '../../components/global/testimonials'

const AboutUs = () => {
    return (
        <Fragment>

            <Introduction
                height={85}
                title1="ABOUT US"
                mainTitle="WHY WORK WITH US?"
                title2="We are One Of The Most Trustworthy Crypto Recovery Services and know how to recover your funds, and have helped hundreds of scam victims from around the world to recover millions of dollars of stolen funds."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <FloatingListSection
                top={-150}
                data={floatingData}
            />

            <AboutUsServices />

            <Introduction
                height={70}
                title1="WE CAN RECOVER"
                mainTitle="YOUR LOST FUNDS"
                title2="We have the skills and resources necessary to give you the best possible chance of cryptocurrency recovery."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />
            <FloatingListSection
                top={-100}
                data={floatingData2}
            />

            <Testimonials
                title='WE’VE HELPED MANY PEOPLE'
                subTitle='RECOVER THEIR LOST FUNDS'
            />
        </Fragment>
    )
}

export default AboutUs