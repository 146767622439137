import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background2 from '../../assets/images/blurCryptoBackgroud2.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import StyledAccordion from '../../components/global/accordion'
import { moreData } from '../../data'
import FloatingContainer from '../../components/global/floatingContainer'

const More = () => {
    return (
        <Fragment>

            <Introduction
                height={85}
                title1="DISCOVER HOW WE CAN"
                mainTitle="RECOVER YOUR LOST FUNDS"
                title2="Cryptocurrencies can be lost or inaccessible for a number of reasons. We can help with a wide range of missing and inaccessible cryptocurrencies. Some of the most common reasons for loss of cryptocurrency investments include:"
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <FloatingContainer
                children={
                    <StyledAccordion
                        title='We help with lost or inaccessible cryptocurrency.'
                        data={moreData}
                    />
                }
            />

        </Fragment>
    )
}

export default More