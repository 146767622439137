import React from 'react'
import './styles.scss'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom"


const UserDropdown = () => {
    const cookie = new Cookies();
    const navigate = useNavigate();

    const str1 = cookie.get('firstname');
    const str2 = cookie.get('lastname');
    const firstname = str1?.charAt(0)?.toUpperCase();
    const lastname = str2?.charAt(0)?.toUpperCase();

    const logOutUserMenu = () => {
        cookie.remove('token');
        navigate("/");
    }

    return (
        <DropdownButton id="dropdown-basic-button" title={firstname + " " + lastname}>
            <Dropdown.Item onClick={() => logOutUserMenu()}>LogOut</Dropdown.Item>
        </DropdownButton>
    )
}

export default UserDropdown