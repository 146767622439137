import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background2 from '../../assets/images/blurCryptoBackgroud2.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import FloatingListSection from '../../components/global/floatingsection'
import { floatingData, floatingData2 } from '../../data'
import RecoveryService from '../../components/homepage/recoveryservice'
import Testimonials from '../../components/global/testimonials'

const HomePage = () => {

    return (
        <Fragment>

            <Introduction
                height={85}
                title1="LOST YOUR CRYPTOCURRENCY?"
                mainTitle="WE RECOVER YOUR LOST FUNDS"
                title2="With no money upfront"
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <FloatingListSection
                top={-150}
                data={floatingData}
            />

            <RecoveryService />

            <Introduction
                height={70}
                title1="DISCOVER HOW WE CAN RECOVER YOUR LOST FUNDS"
                mainTitle="HOW DOES OUR SERVICE WORK?"
                title2="Do you need to recover cryptocurrency funds lost due to theft,
                 fraud or scam? We know how to recover your funds, and have helped hundreds of scam
                  victims from around the world to recover millions of dollars of stolen funds."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />
            <FloatingListSection
                top={-100}
                data={floatingData2}
            />

            <Testimonials
                title='TESTIMONIALS'
                subTitle='WHAT OUR CLIENTS HAVE TO SAY'
            />

        </Fragment>
    )
}

export default HomePage