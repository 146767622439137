import {
    FaUsers,
    FaHandshake,
    FaMedkit, FaBitcoin,
    FaIdCard,
    FaUserLock,
    FaMask,
    FaExchangeAlt,
    FaShieldVirus
} from "react-icons/fa";
import { TfiReload } from 'react-icons/tfi'
import { HiMailOpen } from 'react-icons/hi'
import { MdHeadsetMic, MdOutlineSyncProblem, MdOutlineVisibilityOff } from "react-icons/md";
import { SiSpeedtest } from "react-icons/si";
import { BsCoin, BsFillClipboardDataFill } from 'react-icons/bs';
import { AiOutlineWarning, AiOutlineCloudDownload } from 'react-icons/ai';
import { FiHardDrive } from 'react-icons/fi';
import { GiBreakingChain } from 'react-icons/gi';

import avatar1 from './assets/images/avatar1.jpg'
import avatar2 from './assets/images/avatar2.jpg'
import avatar3 from './assets/images/avatar3.jpg'
import avatar4 from './assets/images/avatar4.jpg'
import avatar5 from './assets/images/avatar5.jpg'
import avatar6 from './assets/images/avatar6.jpg'
import avatar7 from './assets/images/avatar7.jpg'
import avatar8 from './assets/images/avatar8.jpg'
import avatar9 from './assets/images/avatar9.jpg'
import avatar10 from './assets/images/avatar10.jpg'
import avatar11 from './assets/images/avatar11.jpg'
import avatar12 from './assets/images/avatar12.jpg'


export const links = [
    {
        link: 'Home',
        path: '/',
    },
    {
        link: 'About Us',
        path: '/aboutus',
    },
    {
        link: 'Services',
        path: '/services',
    },
    {
        link: 'more',
        path: '/more',
    },
]

export const loggedInLinks = [
    {
        link: 'Profile',
        path: '/profile'
    },
    {
        link: 'My Ticket',
        path: '/ticket'
    },
    {
        link: 'Ticket Progress',
        path: '/ticket-progress'
    },
]

export const floatingData = [
    {
        id: '1',
        title: 'Expert Advice',
        description: 'We have a team of professional recovery experts with years of experience dealing with online financial and cyber fraud.',
        icon: <FaUsers size={70} className='lightTheme' />,
        className: 'lightTheme'
    },
    {
        id: '2',
        title: 'Support & Trust',
        description: 'We are here to provide you with the ultimate solution and help you throughout the process of recovering what you think is lost.',
        icon: <MdHeadsetMic size={70} className='lightTheme' />,
        className: 'lightTheme'
    },
    {
        id: '3',
        title: 'Quick Results',
        description: 'We offer a quick, efficient and simple process. In most cases, we will be able to get a result in the first few weeks.',
        icon: <SiSpeedtest size={70} className='DarkTheme' />,
        className: 'DarkTheme'
    },

]

export const floatingData2 = [
    {
        id: '1',
        title: 'Get Help',
        description: 'Book a professional Recovery Consultant to review your case.',
        icon: <FaHandshake size={70} className="DarkTheme" />,
        className: 'DarkTheme'
    },
    {
        id: '2',
        title: 'Build Your Case',
        description: 'Collect the necessary evidence and to build a strategy for success.',
        icon: <FaMedkit size={70} className='lightTheme' />,
        className: 'lightTheme'
    },
    {
        id: '3',
        title: 'Recover Your Founds',
        description: 'With the right solution, let’s recover your money now!',
        icon: <FaBitcoin size={70} className='DarkTheme' />,
        className: 'DarkTheme'
    },

]

export const testimonials = [
    {
        id: 1,
        name: "John Smith",
        description: "Crypto Recovery made it incredibly simple for me to recover my cryptocurrency. Their efficient and hassle-free process saved me a lot of time and frustration.",
        rating: '5',
        avatar: avatar1,
    },
    {
        id: 2,
        name: "Emma Johnson",
        description: "I am amazed at how Crypto Recovery helped me regain access to my crypto assets. They provided excellent service and support throughout the recovery process.",
        rating: '5',
        avatar: avatar6,
    },
    {
        id: 3,
        name: "Michael Brown",
        description: "Thanks to Crypto Recovery, I was able to recover my lost crypto funds quickly and efficiently. Their expertise and professionalism are unmatched.",
        rating: '5',
        avatar: avatar2,
    },
    {
        id: 4,
        name: "Sophia Davis",
        description: "Crypto Recovery exceeded my expectations in recovering my cryptocurrency. Their team of experts guided me every step of the way and restored my faith in crypto security.",
        rating: '5',
        avatar: avatar7,
    },
    {
        id: 5,
        name: "William Wilson",
        description: "I cannot thank Crypto Recovery enough for their outstanding service. They retrieved my lost crypto assets with ease, providing peace of mind and financial relief.",
        rating: '5',
        avatar: avatar3,
    },
    {
        id: 6,
        name: "Olivia Thompson",
        description: "With Crypto Recovery's help, I was able to recover my locked crypto wallet. Their professional approach and advanced techniques are truly remarkable.",
        rating: '5',
        avatar: avatar8,
    },
    {
        id: 7,
        name: "James Anderson",
        description: "Crypto Recovery is the go-to solution for anyone facing cryptocurrency loss. Their team's expertise and dedication are unmatched in the industry.",
        rating: '5',
        avatar: avatar4,
    },
    {
        id: 8,
        name: "Ava Martinez",
        description: "I was skeptical at first, but Crypto Recovery proved their capabilities by successfully recovering my lost crypto assets. Highly recommended!",
        rating: '5',
        avatar: avatar9,
    },
    {
        id: 9,
        name: "Benjamin Taylor",
        description: "Crypto Recovery's knowledge and expertise are exceptional. They successfully recovered my lost funds, providing me with a second chance in the crypto world.",
        rating: '5',
        avatar: avatar5,
    },
    {
        id: 10,
        name: "Mia Clark",
        description: "I had given up hope on recovering my lost cryptocurrency until I found Crypto Recovery. They managed to restore my funds and gave me a new perspective on crypto security.",
        rating: '5',
        avatar: avatar10,
    },
    {
        id: 11,
        name: "Ethan Turner",
        description: "Crypto Recovery went above and beyond to help me retrieve my lost crypto assets. Their personalized approach and expertise made all the difference in my recovery journey.",
        rating: '5',
        avatar: avatar11,
    },
    {
        id: 12,
        name: "Isabella Rodriguez",
        description: "I highly recommend Crypto Recovery for anyone facing crypto-related issues. They worked tirelessly to recover my funds, and their exceptional service left me impressed.",
        rating: '5',
        avatar: avatar12,
    }
]

export const services = [
    {
        name: 'Fraud',
        icon: <FaIdCard size={70} color="#fff" />,
        description: 'Scamming was the greatest form of cryptocurrency-based crime in 2021.',
    },
    {
        name: 'Transfer Problems',
        icon: <TfiReload size={70} color="#fff" />,
        description: 'Was your cryptocurrency accidentally sent to the wrong address?',
    },
    {
        name: 'Stolen Coins',
        icon: <FaMask size={70} color="#fff" />,
        description: 'Stories of ‘crypto millionaires’ attracted some investors to try their hand at investing in cryptocurrencies or crypto-related investments.',
    },
    {
        name: 'Online Harrasment & Blackmail',
        icon: <HiMailOpen size={70} color="#fff" />,
        description: 'Sometimes, victims are told that most or all their money will assuredly be returned if they first pay a small donation.',
    },
    {
        name: 'Lost Password',
        icon: <FaUserLock size={70} color="#fff" />,
        description: 'Tens of billions worth of Bitcoin have been locked by people who forgot their key.',
    },
    {
        name: 'Forked Coins',
        icon: <BsCoin size={70} color="#fff" />,
        description: 'Forks are splits that happen in the transaction chain based on different user opinions about transaction history.',
    },
    {
        name: 'Exchange Hacking',
        icon: <FaExchangeAlt size={70} color="#fff" />,
        description: 'Sometimes, victims are told that most or all their money will assuredly be returned if they first pay a small donation, retainer, or overdue taxes.',
    },
    {
        name: 'Ransomware Attack',
        icon: <AiOutlineWarning size={70} color="#fff" />,
        description: 'We recover your ransom coins',
    },
    {
        name: 'Damaged Hard Discs',
        icon: <FiHardDrive size={70} color="#fff" />,
        description: 'Corrupt Data due to hardware errors, and defects.',
    },
    {
        name: 'Viruses & Ransomware',
        icon: <FaShieldVirus size={70} color="#fff" />,
        description: 'If the computer or device with your cryptocurrencies is infected with Viruses, we help you to try to clear the virus without putting your cryptocurrency in jeopardy.',
    },
    {
        name: 'Software Problems',
        icon: <MdOutlineSyncProblem size={70} color="#fff" />,
        description: 'Corrupt Data due to hardware errors, and defects.',
    },
    {
        name: 'Data Breaches',
        icon: <BsFillClipboardDataFill size={70} color="#fff" />,
        description: 'we recover crypto currency lost in data breaches.',
    },
]

export const highlights = [
    {
        icon: <AiOutlineCloudDownload size={70} color="#0068FF" />,
        title: 'DIGITAL ASSET RECOVERY',
        description: 'Do you need to recover cryptocurrency funds (Bitcoin, Ethereum, or other) lost due to theft, fraud or scam? We know how to recover your funds, and have helped hundreds of scam victims from around the world to recover millions of dollars of stolen funds. Our investigations can help to uncover who the perpetrators are, monitor crypto movements in real- time, and ultimately help with seizing and recovering your funds.'
    },
    {
        icon: <GiBreakingChain size={70} color="#0068FF" />,
        title: 'BLOCKCHAIN FORENSICS',
        description: 'Our services include investigation and blockchain forensics, writing of reports, cooperate with exchanges and law enforcement, expert witness testimony, and more… We work with established and leading vendors, experts and professional blockchain forensics tools, and use a combination of proven blockchain analytics and investigative techniques to successfully return your funds , and ultimately help with seizing and recovering your funds.'
    },
    {
        icon: <MdOutlineVisibilityOff size={70} color="#0068FF" />,
        title: 'HIDDEN CRYPTOCURRENCY',
        description: 'Because of Cryptocurrencies nature, people don’t always disclose it even when they’re required to do so by law. Cryptocurrency has become a favorite way for individuals to try and hide wealth in the case of Divorce, Embezzlement, Bankruptcy, Financial Fraud, and Tax Fraud. We track and audit cryptocurrency as it moves on the blockchain from wallet to wallet while tracking ownership of the assets with it, and provide forensics reports, declarations, and expert witnesses.'
    },
]

export const moreData = [
    {
        eventKey: '0',
        header: "FRAUD -SCAMMED BY CRYPTO TRADING?",
        description: ' Crypto scams are the top threat to investors by far, say securities according to a survey of securities regulators NASAA (North American Securities Administrators Association) Scamming was the greatest form of cryptocurrency-based crime in 2021, followed by theft — most of which occurred through hacking of cryptocurrency businesses. Scammers took home a record $14 billion in cryptocurrency in 2021,Stories of ‘crypto millionaires’ attracted some investors to try their hand at investing in cryptocurrencies or crypto-related investments. The most common telltale sign of an investment scam is an offer of guaranteed high returns with no risk If you have lost bitcoin or cryptocurrency to a fraudulent scheme, you can recover the lost cryptocurrency using Crypto Recovery. We are One of The Most Trustworthy Crypto Recovery Services and know how to recover your funds, and have helped hundreds of scam victims from around the world to recover millions of dollars of stolen funds.'
    },
    {
        eventKey: '1',
        header: "“CRYPTO RECOVERY SERVICE” FRAUDS",
        description: 'If you recently lost a portion of your savings or retirement nest egg to a cryptocurrency, an offer to recover your lost funds may sound very appealing. Unfortunately, for many victims of fraud, the offer may be another scheme that adds insult to injury. Recovery scams are a form of advance-fee fraud—when you are asked to pay upfront for the chance of getting a much bigger sum of money later. Recovery frauds target victims already harmed by other frauds. Sometimes, victims are told that most or all their money will assuredly be returned if they first pay a small donation, retainer, or overdue taxes. However, after making the first payment, requests for more funds often follow. If you’ve been a recent victim of fraud, be prepared to guard against these follow-on schemes. Speak to one of our specialists at Crypto Recovery, We are One Of The Most Trustworthy Crypto Recovery Services and know how to recover your funds, and have helped hundreds of scam victims from around the world to recover millions of dollars of stolen funds.'
    },
    {
        eventKey: '2',
        header: "FORGET OR MISPLACE YOUR PASSWORD",
        description: ' Tens of billions worth of Bitcoin have been locked by people who forgot their key. Analysis by the Wall Street Journal suggests 20% of all existing Bitcoin tokens — currently worth around $140 billion, have been misplaced by users and appears to be in lost or otherwise stranded wallets. Because Bitcoin investors hold their tokens in digital wallets that’s protected by cryptography and accessible only via private key, if the wallet owner loses his or her access key, that wallet may be permanently inaccessible, along with the tokens it contains. The cryptocurrency’s unusual nature has meant that many people are locked out of their Bitcoin fortunes as a result of lost or forgotten keys. They have been forced to watch, helpless, as the price has risen and fallen sharply, unable to cash in on their digital wealth. Bitcoin owners who are locked out of their wallets speak of endless days and nights of frustration as they have tried to get access to their fortunes. Many have owned the coins since Bitcoin’s early days a decade ago, when no one had confidence that the tokens would be worth anything.If you forget or misplace your password, the funds are completely inaccessible. Crypto Recovery engineers can help. We have established techniques for recovering or breaking cryptocurrency wallet passwords, and in many cases, we can restore access within several days.'
    },
    {
        eventKey: '3',
        header: "DELETED YOUR WALLET",
        description: 'Accidental deletion is one of the most common data loss scenarios. In the vast majority of cases, we can successfully recover deleted wallets without any data loss, and we offer no data, no charge guarantee.Important note: If you have accidentally deleted a wallet, turn off the affected device immediately to avoid permanent data loss. If you accidentally deleted data, and you fear your cryptocurrencies are lost, Crypto Recovery can help to recover deleted data.'
    },
    {
        eventKey: '4',
        header: "HARDWARE, SOFTWARE ERRORS AND VIRUSES:",
        description: 'Corrupt Data occurs due to software or hardware errors, and defects, even coins stored on mobile phones, computers, USB drives, or hard discs it can prevent cryptocurrency owners from accessing their coins — even with a correct password. Crypto Recovery can definitely help restore the affected files and sometimes within hours. If the computer or device with your cryptocurrencies is infected with Viruses, let Crypto Recovery help you to try to clear the virus without putting your cryptocurrency in jeopardy. To date we have recovered large amount of cryptocurrency from failed hard drives, RAID arrays, flash drives, SSD’s and other devices. As one of the most trusted crypto recovery services, we offer the highest success rates available anywhere, and we use state-of-the-art technology. If your data is still on your device, we have the necessary tools to recover it.'
    },
    {
        eventKey: '5',
        header: "TROUBLE UPGRADING OLDER WALLET VERSIONS",
        description: 'If you are not able to open your wallet with the current version of wallet software or uncomfortable upgrading older wallets. But you know your password, Crypto Recover can help you to resolve the software problem. We can also provide safe, secure upgrades, providing you with an up-to-date wallet while maintaining your privacy.'
    },
    {
        eventKey: '6',
        header: "TRANSFERRED TO AN INVALID ADDRESS",
        description: 'Was your cryptocurrency accidentally sent to the wrong address? Or maybe the funds were never received by the recipient, Crypto Recovery is guaranteed to help you recover them.'
    },
    {
        eventKey: '7',
        header: "YOUR FUNDS LOST IN FORKS",
        description: 'Forks are splits that happen in the transaction chain based on different user opinions about transaction history. If you have a bitcoin balance that predates the fork, Crypto Recovery work with established and leading vendors, experts and professional blockchain forensics tools, and use a combination of proven blockchain analytics and investigative techniques to successfully return your funds.'
    },


]

export const serviceHighlight = [
    {
        eventKey: '0',
        header: "Which Currencies/Wallets Can You Recover?",
        description: 'We will attempt to recover any wallet and any cryptocurrency.'
    },
    {
        eventKey: '1',
        header: "What are my Chances Of Recovering My Funds?",
        description: 'Ultimately, there are no guarantees. We assess each case that meets our criteria (including the minimum loss threshold) individually, and offer an (honest) opinion of whether it may be worth pursuing and/or engaging us. In some cases, we will still advise against pursuing or decline to engage even if the minimum loss threshold is met if we don’t feel it’s in the best interest of a prospective client to hire us.'
    },
    {
        eventKey: '2',
        header: "What's The Cost?",
        description: 'There is no one fit all approach. Multiple factors such as the value of your holdings, various security complications, legal issues, and other complexities may affect the timeframe and cost of your recovery. Sometimes we  may take cases on a contingency-only basis. Our all-inclusive fee starts at as low as 15% of the recovered amount .'
    },
    {
        eventKey: '3',
        header: "How Long Does It Take To Recover?",
        description: 'We offer a quick, efficient and simple process. In most cases, we will be able to get a result in the first few weeks.'
    },
    {
        eventKey: '4',
        header: "How Do You Retrun The Cryptocurrency?",
        description: 'If the recovery effort is a success, your cryptocurrency will be returned to you. When the computer and investigation experts have done their job, a selected Law Firm ensures the money is returned to you so you’ll have full access to your valuable investment going forward.'
    },


]