import React from 'react'
import './styles.scss'
import Accordion from 'react-bootstrap/Accordion';
import { Container } from 'react-bootstrap';


const StyledAccordion = ({ title, data }) => {
    return (
        <div className='AccordionWrapper'>
            <div className='AccordionTitleContainer'>
                <p>{title}</p>
            </div>

            <div className='AccoridonContainer'>
                <Container>

                    <Accordion defaultActiveKey="0">
                        {
                            data?.map(d => (
                                <Accordion.Item eventKey={d.eventKey}>
                                    <Accordion.Header className='accordionHeader'>{d.header}</Accordion.Header>
                                    <Accordion.Body>
                                        {d.description}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        }

                    </Accordion>

                </Container>

            </div>
        </div>
    )
}

export default StyledAccordion