import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.scss'

const FloatingContainer = ({ children }) => {
    return (
        <Container>
            <div className='floatingContainerWrapper' data-aos="fade-up" data-aos-duration="1000">
                {children}
            </div>
        </Container>
    )
}

export default FloatingContainer