import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import PublicLayout from './layout/publiclayout';
import HomePage from './pages/homepage';
import AboutUs from './pages/aboutus';
import Services from './pages/services';
import More from './pages/more';
import AuthLayout from './layout/authlayout';
import SignUp from './pages/signup';
import SignIn from './pages/signin';
import UserLayout from './layout/userlayout';
import Profile from './pages/profile';
import MyTicket from './pages/myticket';
import TicketProgress from './pages/ticketprogress';

function App() {
  return (
    <Router>
      <Routes>

        <Route path='/' element={<PublicLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/more" element={<More />} />
        </Route>

        <Route path='/' element={<AuthLayout />}>
          <Route path="signin" element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
        </Route>

        <Route path='/' element={<UserLayout />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/ticket" element={<MyTicket />} />
          <Route path="/ticket-progress" element={<TicketProgress />} />
        </Route>

      </Routes>
    </Router >
  );
}

export default App;
