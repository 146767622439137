import React from 'react'
import './styles.scss'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const RecoveryService = () => {
    return (
        <div className='recoveryServiceWrapper'>
            <Container>
                <div className='recoveryServiceTitleOne' data-aos="fade-up" data-aos-duration="500">
                    <p>ONE OF THE MOST TRUSTWORTHY</p>
                </div>
                <div className='recoveryServiceTitleTwo' data-aos="fade-up" data-aos-duration="1000">
                    <p>CRYPTO RECOVERY SERVICES</p>
                </div>
                <div className='recoveryServiceDescription' data-aos="fade-up" data-aos-duration="1500">
                    <p>
                        We know how to recover your funds, and have helped hundreds of victims
                        from around the world to recover millions of dollars worth of stolen and inaccessible-cryptocurrencies.
                        Let us save you endless days and nights of frustration trying to get access to your fortunes
                    </p>
                </div>
                <div className='recoverySubTitle' data-aos="fade-up" data-aos-duration="2000">
                    <p>WE USE OUR EXPERIENCE TO CREATE YOURS</p>
                </div>
                <div className='recoveryServiceDescription' data-aos="fade-up" data-aos-duration="1500">
                    <p>
                        We work with established and leading vendors, experts and professional blockchain forensics tools,
                        and use a combination of proven blockchain analytics and investigative techniques to successfully
                        return your funds
                    </p>
                </div>
                <div data-aos="fade-up" data-aos-duration="2000">
                    <Link to='/more'>
                        <Button className='recoveryServiceButton'>
                            Find More
                        </Button>
                    </Link>
                </div>
            </Container>

        </div>
    )
}

export default RecoveryService