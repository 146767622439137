import React, { useEffect, useState } from 'react'
import './styles.scss'
import { getTickets } from '../../api';
import Cookies from 'universal-cookie'
import moment from 'moment/moment';
import { Badge } from 'react-bootstrap';



const TicketsProgressFields = () => {
    const cookie = new Cookies()
    const id = cookie.get('id');
    const [ticket, setTicket] = useState([]);

    useEffect(
        () => {
            getTickets(id).then(async res => {
                setTicket(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])

    return (
        <div className='ticketProgressWrapper'>
            <div className='ticketProgressContainer'>
                <table className='ticketProgressTable'>
                    <thead>
                        <tr>
                            <th align='center' className='tableHeader'><b>Ticket Number</b></th>
                            <th align='center' className='tableHeader'><b>Date</b></th>
                            <th align='center' className='tableHeader'><b>Status</b></th>
                        </tr>
                    </thead>
                    <tbody className='tbody'>
                        {
                            ticket.map(item => (
                                <>
                                    <tr key={item._id} class="tableRow">
                                        <td align='center'><p style={{ marginTop: 16 }}>#{item?.generatedNumber}</p></td>
                                        <td align='center'><p style={{ marginTop: 16 }}>{moment(item.createdAt).format('L')}</p></td>
                                        <td align='center'>
                                            {
                                                item.status === 0 ?
                                                    <>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Badge bg="warning">Pending</Badge>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Badge bg="success">Success</Badge>
                                                        </div>
                                                    </>
                                            }
                                        </td>
                                    </tr>

                                </>

                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TicketsProgressFields