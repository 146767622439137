import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './styles.scss'
import { services } from '../../../data'
import { BiCheckCircle } from 'react-icons/bi'
import { Link } from 'react-router-dom'

const AboutUsServices = () => {
    return (
        <div className='aboutusServiceWrapper'>
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12}>
                        <p className='aboutusServiceTitle' data-aos="fade-up" data-aos-duration="500">
                            LOST YOUR CRYPTO CURRENCY?
                        </p>
                    </Col>
                    <Col xxl={12} xl={12} lg={12}>
                        <p className='aboutusServiceSubTitle' data-aos="fade-up" data-aos-duration="1000">
                            Cryptocurrencies can be lost or inaccessible for a number of reasons.
                            We can help with a wide range of missing and inaccessible cryptocurrencies.
                            Some of the most common reasons for loss of cryptocurrency investments include:
                        </p>
                    </Col>
                </Row>
                <Row>
                    <div className='aboutusServicesContainer'>
                        <div className='aboutusService'>
                            {services.slice(0, 6).map((item, index) => (
                                <Link to='/services' style={{ textDecoration: 'none' }}>
                                    <div key={index} data-aos="fade-up" data-aos-duration="1500">
                                        <BiCheckCircle size={28} color='#0600b6' />
                                        <p>{item.name}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                        <div className='aboutusService'>
                            {services.slice(6, 12).map((item, index) => (
                                <Link to='/services' style={{ textDecoration: 'none' }}>
                                    <div key={index} data-aos="fade-up" data-aos-duration="1500">
                                        <BiCheckCircle size={28} color='#0600b6' />
                                        <p>{item.name}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default AboutUsServices