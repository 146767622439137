import React from 'react'
import './styles.scss'
import { services } from '../../../data'

const ServicesCards = () => {
    return (
        <div className='serviceWrapper'>
            <div className='serviceTitle' data-aos="fade-up" data-aos-duration="500">
                <p>Our Services</p>
            </div>
            <div className='serviceCardContainer' data-aos="fade-up" data-aos-duration="1000">

                {
                    services.map(service => (
                        <div className='card'>
                            <div className='cardHeaderContainer'>
                                <div>{service.icon}</div>
                                <div className='cardHeaderName'><p>{service.name}</p></div>
                            </div>
                            <div className='content'>
                                {service.description}
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default ServicesCards