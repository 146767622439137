import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import background2 from '../../assets/images/blurCryptoBackgroud2.jpg'
import mobileBackground from '../../assets/images/mobileBlurCryptoBackgroud.jpg'
import FloatingContainer from '../../components/global/floatingContainer'
import ProfileFields from './profileFields'

const Profile = () => {
    return (
        <Fragment>
            <Introduction
                height={85}
                title1="Customize and Control Your Online Identity"
                mainTitle="My Profile"
                title2="Unleash the Power of Your User Profile: Discover, Personalize, and Connect."
                backgroundImage={background2}
                mobileBackgroundImage={mobileBackground}
                backgroundColor='none'
                mobileBackgroundColor='none'
            />

            <FloatingContainer
                children={
                    <ProfileFields />
                }
            />
        </Fragment>
    )
}

export default Profile